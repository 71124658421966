@import '../../../shared/styles/core.css';

.root {
  all: unset;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: all var(--transition-md);

  &:disabled,
  &.disabled {
    cursor: initial;
  }
}

.button {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-height: 40px;
  padding: 6px 16px;
  border: none;
  border-radius: var(--border-radius-default);
  background-color: var(--priority-primary-80);
  color: var(--default-white-100);
  font-weight: 700;
  font-size: 16px;
  font-family: Manrope, sans-serif;
  line-height: 24px;
  letter-spacing: 0;

  &.secondary {
    border: 1px solid var(--priority-primary-80);
    background-color: var(--default-white-100);
    color: var(--priority-primary-80);

    &:is(:hover, :active) {
      border: 1px solid var(--priority-primary-100);
      background-color: var(--default-white-100);
      color: var(--priority-primary-100);
    }

    &.disabled {
      border: 1px solid var(--default-grey-30);
      background-color: var(--default-white-100);
      color: var(--default-grey-40);
    }
  }

  &.alternative {
    border: 1px solid var(--priority-primary-20);
    background-color: var(--priority-primary-20);
    color: var(--priority-primary-80);

    &:is(:hover, :active) {
      border: 1px solid var(--priority-primary-30);
      background-color: var(--priority-primary-30);
      color: var(--priority-primary-80);
    }

    &.disabled {
      border: 1px solid var(--default-grey-30);
      background-color: var(--default-white-100);
      color: var(--default-grey-40);
    }
  }

  &.ghost {
    border: none;
    background-color: var(--default-white-100);
    color: var(--priority-primary-80);

    &:is(:hover, :active) {
      border: none;
      background-color: var(--default-white-100);
      color: var(--priority-primary-100);
    }

    &:is(:focus-visible) {
      border: 1px solid var(--priority-primary-80);
    }
  }
}

.button:is(:hover, :active) {
  background-color: var(--priority-primary-100);
}

.button:focus-visible {
  outline: 2px solid var(--priority-primary-30);
}

.button.disabled {
  background-color: var(--default-grey-30);
}

.loader {
  width: 14px;
  height: 14px;

  &.right {
    order: 2;
  }

  &.left {
    order: 0;
  }
}

.medium {
  min-height: 32px;
  padding: 2px 16px;
  font-size: 14px;
  line-height: 20px;

  & .loader {
    width: 10px;
    height: 10px;
  }
}

.fullWidth {
  width: 100%;
}

.icon {
  font: inherit;

  &.right {
    order: 2;
  }

  &.left {
    order: 0;
  }
}

.grow {
  flex-grow: 1;
}

.close {
  color: var(--default-grey-40);
}

@media (--screen-xs) {
  .button {
    min-height: 48px;
    padding: 10px 24px;
  }

  .medium {
    min-height: 40px;
    padding: 6px 16px;
    line-height: 22px;
  }
}

@media (--screen-lg) {
  .button {
    min-height: 56px;
    padding: 12px 23px;
  }

  .loader {
    width: 18px;
    height: 18px;
  }

  .medium {
    min-height: 48px;
    padding: 10px 16px;

    & .loader {
      width: 14px;
      height: 14px;
    }
  }
}

@media (--screen-xl) {
  .loader {
    width: 20px;
    height: 20px;
  }

  .medium {
    min-height: 44px;
    padding: 8px 16px;

    & .loader {
      width: 18px;
      height: 18px;
    }
  }
}
